<template>
  <div>
    <v-data-table
      :headers="columns"
      :items="cruddataMX.dataList"
      :items-per-page="5"
      item-key="uuid"
      class="elevation-1"
    >
      <template #createdTime="{ item }">
        {{ datetime(item.createdTime) }}
      </template>
      <template #roleVos="{ item }">
        {{ getname(item.roleVos) }}
      </template>
      <template #top>
        <v-toolbar flat>
          <!-- <v-toolbar-title>My CRUD</v-toolbar-title> -->
          <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
          <!-- <v-spacer></v-spacer> -->
          <v-dialog v-model="crud.switch" max-width="500px" persistent>
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="crudActionMX().newItem(crud.default)"
              >
                新增路由
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span>{{ crud.title }}</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="crudActionMX().closeEditingPage()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text>
                <v-form :ref="crud.form.ref">
                  <v-text-field
                    v-model="cruddataMX.data.name"
                    label="菜单"
                  ></v-text-field>
                  <v-text-field
                    v-model="cruddataMX.data.icon"
                    label="图标"
                  ></v-text-field>
                  <v-text-field
                    v-model="cruddataMX.data.sign"
                    label="权限KEY"
                  ></v-text-field>
                  <v-text-field
                    v-model="cruddataMX.data.path"
                    label="路径"
                  ></v-text-field>
                  <v-text-field
                    v-model="cruddataMX.data.component"
                    label="Vue组件"
                  ></v-text-field>
                  <v-text-field
                    v-model="cruddataMX.data.parentUuid"
                    label="父菜单uuid"
                  ></v-text-field>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="crudActionMX().closeEditingPage()"
                >
                  取消
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="crudActionMX().saveItem()"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #actions="{ item }">
        <v-icon class="mr-2" @click="crudActionMX().editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon @click="crudActionMX().deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      crud: {
        name: '路由',
        title: '', // 在Mixin中被修改
        switch: false,
        uploading: false,
        loading: false,
        form: {
          ref: 'accountModalForm',
        },
        select: {
          roles: {
            loading: false,
            current: 1,
            defaultPageSize: 10,
            data: [],
          },
        },
        default: {
          name: '',
          sign: '',
          path: '',
          component: '',
          icon: '',
        },
      },
      columns: [
        { text: '序号', value: 'id' },
        { text: '菜单', value: 'name' },
        { text: '权限Key', value: 'sign' },
        { text: '路径', value: 'path' },
        { text: 'Vue组件', value: 'component' },
        { text: '图标', value: 'icon' },
        { text: '操作', value: 'actions', sortable: false },
      ],
      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },
  computed: {
    datetime() {
      return (timestamp) => this.$util.data.dataFormat(timestamp);
    },
    getname() {
      return (item) => item.map((p) => p.name).toString();
    },
  },
  created() {
    this.crudInitMX(
      this.crud,
      {
        list: this.$api.system.account.getRoute,
        add: this.$api.system.account.addRoute,
        edit: this.$api.system.account.editRoute,
        delete: this.$api.system.account.deleteRoute,
      },
      {},
      {
        add: ['name', 'sign', 'path', 'component', 'icon', 'parentUuid'],
        edit: [
          'uuid',
          'name',
          'sign',
          'path',
          'component',
          'icon',
          'parentUuid',
        ],
      }
    );
  },
};
</script>
